<template>
	<div class="gizmo-vev-component">
		<ClientOnly>
			<component
				is="script"
				:src="url"
				v-if="url"
			></component>
			<div v-else>Missing or invalid Vev URL</div>
		</ClientOnly>
	</div>
</template>

<script>
import GizmoComponentMixin from "~/mixins/platform/GizmoComponentMixin.js";

export default defineNuxtComponent({
	name: "GizmoVevComponent",
	components: {},
	mixins: [GizmoComponentMixin],

	computed: {
		url() {
			if (this.component.content.vevUrl) {
				if (/https:\/\/embed.vev.page\/.+/.test(this.content.vevUrl)) {
					return this.content.vevUrl;
				} else {
					return "";
				}
			} else {
				return "";
			}
		},
	},
});
</script>

<style lang="scss">
.gizmo-vev-component {
	overflow-x: hidden;
}
</style>
